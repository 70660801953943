import { FC, useEffect, useState } from "react";
import apiAxiosInstance from "../../utils/httpConfig/axiosInstance";
import { defaultOptions } from "../../components/shared/lotieAnimationOptions";
import Lottie from "react-lottie";
import { Response } from "../../components/response/Response";
import { MESSAGES } from "../../utils/messages/messages";

export const HomePage: FC = () => {
  const [validate, setValidate] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    validateUser();
  }, []);

  const validateUser = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const params = {
      userEmail: urlParams.get("Email"),
    };
    await apiAxiosInstance
      .get("ValidateEmail", {
        params,
        headers: {
          ApiKey: process.env.REACT_APP_AUTH_API_KEY,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.successful) {
          setValidate(true);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <div className="container">
      {loading ? (
        <Lottie
          options={defaultOptions}
          isStopped={false}
          isPaused={false}
          height={400}
          width={400}
        />
      ) : (
        <Response
          validate={validate}
          succes={MESSAGES.SUCCES_VALIDATE_EMAIL}
          fail={MESSAGES.FAIL_VALIDATE_EMAIL}
          titleSucces={MESSAGES.TITLE_SUCCES_VALIDATE_EMAIL}
          titleFail={MESSAGES.TITLE_FAIL_VALIDATE_EMAIL}
          subtitleSucces1={MESSAGES.SUBTITLE_SUCCES_1_VALIDATE_EMAIL}
          subtitleSucces2={MESSAGES.SUBTITLE_SUCCES_2_VALIDATE_EMAIL}
          subtitleFail={MESSAGES.SUBTITLE_FAIL_VALIDATE_EMAIL}
        />
      )}
    </div>
  );
};
