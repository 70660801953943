import { FC, useEffect, useState } from "react";
import apiAxiosInstance from "../../utils/httpConfig/axiosInstance";
import { defaultOptions } from "../../components/shared/lotieAnimationOptions";
import Lottie from "react-lottie";
import { Response } from "../../components/response/Response";
import { MESSAGES } from "../../utils/messages/messages";

export const ForgotPasswordPage: FC = () => {
  const [validate, setValidate] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    validateUser();
  }, []);

  const validateUser = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get("token")
    await apiAxiosInstance
      .post("ConfirmResetPassword", null, {
        headers: {
          Authorization: `Bearer ${token}`
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setValidate(true);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <div className="container">
      {loading ? (
        <Lottie
          options={defaultOptions}
          isStopped={false}
          isPaused={false}
          height={400}
          width={400}
        />
      ) : (
        <Response
          validate={validate}
          succes={MESSAGES.SUCCES_FORGOT_PASSWORD}
          fail={MESSAGES.FAIL_FORGOT_PASSWORD}
          titleSucces={MESSAGES.TITLE_SUCCES_FORGOT_PASSWORD}
          titleFail={MESSAGES.TITLE_FAIL_FORGOT_PASSWORD}
          subtitleSucces1={MESSAGES.SUBTITLE_SUCCES_1_FORGOT_PASSWORD}
          subtitleSucces2={MESSAGES.SUBTITLE_SUCCES_2_FORGOT_PASSWORD}
          subtitleFail={MESSAGES.SUBTITLE_FAIL_FORGOT_PASSWORD}
        />
      )}
    </div>
  );
};
