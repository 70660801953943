interface IMessageObject {
    [key: string]: string
}

export const MESSAGES: IMessageObject = {
    SUCCES_VALIDATE_EMAIL: 'Cuenta validada exitosamente',
    FAIL_VALIDATE_EMAIL: 'No se pudo validar la cuenta',
    TITLE_SUCCES_VALIDATE_EMAIL: 'Te hemos enviado los datos de tu usuario por correo.',
    TITLE_FAIL_VALIDATE_EMAIL: 'Vuelva a validar la cuenta ingresando desde el correo recibido.',
    SUBTITLE_SUCCES_1_VALIDATE_EMAIL: 'Cuando ingrese por primera vez a la aplicacion movil de Synagro,',
    SUBTITLE_SUCCES_2_VALIDATE_EMAIL: 'deberá cambiar la contraseña enviada por una nueva.',
    SUBTITLE_FAIL_VALIDATE_EMAIL: 'Si el problema persiste, contacte a soporte.',
    SUCCES_FORGOT_PASSWORD: 'Se realizó la modificación de la contraseña de manera exitosa',
    FAIL_FORGOT_PASSWORD: 'No se pudo realizar la modificación de la contraseña',
    TITLE_SUCCES_FORGOT_PASSWORD: 'La nueva contraseña para su usuario fue enviada por correo',
    TITLE_FAIL_FORGOT_PASSWORD: 'Vuelva a intentar modificar la contraseña desde el correo recibido.',
    SUBTITLE_SUCCES_1_FORGOT_PASSWORD: 'Cuando ingrese nuevamente a la aplicación móvil de SYNAgro,',
    SUBTITLE_SUCCES_2_FORGOT_PASSWORD: 'deberá cambiar esa contraseña por una nueva.',
    SUBTITLE_FAIL_FORGOT_PASSWORD: 'Si el problema persiste, contacte a soporte.',
}
