import { FC } from "react";
import LogoSynagro from "../../assets/img/Logo-synagro.svg";
import LogoUserSucces from "../../assets/img/Logo-user-succes.svg";
import LogoUserFail from "../../assets/img/Logo-user-rejected.svg";
import LogoEmail from "../../assets/img/Logo-email.svg";
import { IResponseInterface } from "./response.interface";
import './response.css'

export const Response: FC<IResponseInterface> = ({
  validate,
  succes,
  fail,
  titleSucces,
  titleFail,
  subtitleSucces1,
  subtitleSucces2,
  subtitleFail,
}) => {
  return (
    <div className="validate-container">
      <div className="section-container">
        <img
          src={LogoSynagro}
          className="container-img-logo"
          alt="Logo Synagro"
        />
      </div>
      <div className="hr-container">
        <hr />
      </div>
      <div className="section-container">
        <div>
          <img
            src={validate ? LogoUserSucces : LogoUserFail}
            className="container-img-logo"
            alt="Succes or fail"
          />
        </div>
        {validate ? (
          <h1 className="main-title">{succes}</h1>
        ) : (
          <h1 className="main-title-fail">{fail}</h1>
        )}
      </div>
      <div className="hr-container">
        <hr />
      </div>
      <div className="footer-container">
        <div className="info-container">
          {validate ? (
            <span className="info-span">
              {" "}
              <img className="email" src={LogoEmail} alt="Succes" />
              {titleSucces}
            </span>
          ) : (
            <span className="info-span">
              <img className="email" src={LogoEmail} alt="fail" />
              {titleFail}
            </span>
          )}

          {validate ? (
            <>
              <span className="info-span">{subtitleSucces1}</span>
              <p className="info-link">
                <span className="info-span">{subtitleSucces2}</span>
              </p>
            </>
          ) : (
            <span className="info-span">{subtitleFail}</span>
          )}
        </div>
      </div>
    </div>
  );
};
