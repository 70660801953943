import { FC } from "react";
import "./errorPage.css";
import LogoSynagro from "../../assets/img/Logo-synagro.svg";

export const ErrorPage: FC = () => {
  return (
    <div className="container">
      <div className="validate-container">
        <div className="section-container">
          <img src={LogoSynagro} className="container-img-logo" alt="Logo Synagro" />
        </div>
        <div className="hr-container">
          <hr />
        </div>
        <div className="section-container">
          <h1 className="main-title-fail">
            La página que estás buscando no existe
          </h1>
        </div>
        <div className="hr-container">
          <hr />
        </div>
      </div>
    </div>
  );
};
