import axios from "axios";
import {
  errorRequestInterceptor,
  errorResponseInterceptor,
  successRequestInterceptor,
  successResponseInterceptor,
} from "./interceptors";

const apiAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_AUTH_URL,
});

apiAxiosInstance.interceptors.request.use(
  successRequestInterceptor,
  errorRequestInterceptor
);

apiAxiosInstance.interceptors.response.use(
  successResponseInterceptor,
  errorResponseInterceptor
);

export default apiAxiosInstance;
